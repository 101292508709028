<template>
  <div id="reagentUseIssueRecord">
    <el-dialog
      :title="reagentUseIssueRecordFormTitle"
      width="1200px"
      :visible.sync="reagentUseIssueRecordDialogVisible"
      :close-on-click-modal="false"
      @close="reagentUseIssueRecordDialogClose"
    >
      <div id="pdfDom">
        <h1 style="text-align: center">
          试剂试药领用、发放记录
        </h1>
        <el-form
          ref="reagentUseIssueRecordFormRef"
          :model="reagentUseIssueRecordForm"
          :rules="reagentUseIssueRecordFormRules"
          label-position="right"
          label-width="120px"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="品名" prop="productName">
                <el-input v-model="reagentUseIssueRecordForm.productName" placeholder="请输入品名" clearable />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="规格型号" prop="spec">
                <el-input v-model="reagentUseIssueRecordForm.spec" placeholder="请输入规格型号" clearable />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="批号" prop="batchNo">
                <el-input v-model="reagentUseIssueRecordForm.batchNo" placeholder="请输入批号" clearable />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="有效期" prop="validPeriod">
                <el-input v-model="reagentUseIssueRecordForm.validPeriod" placeholder="请输入有效期" clearable />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="生产商" prop="manufacturer">
                <el-input v-model="reagentUseIssueRecordForm.manufacturer" placeholder="请输入生产商" clearable />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开启日期" prop="openDate">
                <el-date-picker v-model="reagentUseIssueRecordForm.openDate" placeholder="请选择开启日期" value-format="yyyy-MM-dd" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="启用人" prop="enabler">
                <el-input v-model="reagentUseIssueRecordForm.enabler" placeholder="请输入启用人" clearable />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开启后有效期" prop="validityPeriodAfter">
                <el-input v-model="reagentUseIssueRecordForm.validityPeriodAfter" placeholder="请输入开启后有效期" clearable />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="领用发放情况">
                <vxe-toolbar v-if="reagentUseIssueRecordFormTitle !== '试剂试药领用、发放记录详情'">
                  <template #buttons>
                    <vxe-button @click="insertRowEvent()">
                      新增
                    </vxe-button>
                  </template>
                </vxe-toolbar>
                <vxe-table
                  ref="xTable"
                  border
                  resizable
                  show-overflow
                  keep-source
                  :data="useIssueDetailList"
                  :edit-config="{trigger: 'click', mode: 'cell'}"
                >
                  <vxe-table-column
                    field="requisitionDate"
                    title="领用日期"
                    width="150"
                    :edit-render="{name: '$input', props: {type: 'date', clearable: true}}"
                  />
                  <vxe-table-column
                    field="receiptAmount"
                    title="领用数量"
                    :edit-render="{name: '$input', props: {clearable: true}}"
                  />
                  <vxe-table-column
                    field="purpose"
                    title="用途"
                    :edit-render="{name: '$input', props: {clearable: true}}"
                  />
                  <vxe-table-column
                    field="remainingAmount"
                    title="预计剩余数量"
                    :edit-render="{name: '$input', props: {clearable: true}}"
                  />
                  <vxe-table-column
                    field="recipient"
                    title="领用人"
                    :edit-render="{name: '$input', props: {clearable: true}}"
                  />
                  <vxe-table-column
                    field="issuer"
                    title="发放人"
                    :edit-render="{name: '$input', props: {clearable: true}}"
                  />
                  <vxe-table-column
                    field="remarks"
                    title="备注"
                    :edit-render="{name: '$input', props: {clearable: true}}"
                  />
                  <vxe-table-column v-if="reagentUseIssueRecordFormTitle !== '试剂试药领用、发放记录详情'" title="操作" width="100">
                    <template #default="{ row }">
                      <template>
                        <vxe-button @click="removeRowEvent(row)">
                          删除
                        </vxe-button>
                      </template>
                    </template>
                  </vxe-table-column>
                </vxe-table>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="reagentUseIssueRecordDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="reagentUseIssueRecordFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="reagentUseIssueRecordFormTitle === '试剂试药领用、发放记录详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="品名">
        <el-input v-model="searchForm.productName" placeholder="请输入品名" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="reagentUseIssueRecordPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="productName" label="品名" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="validPeriod" label="有效期" />
      <el-table-column prop="manufacturer" label="生产商" />
      <el-table-column label="开启日期">
        <template slot-scope="scope">
          <span v-if="scope.row.openDate">{{ scope.row.openDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="enabler" label="启用人" />
      <el-table-column prop="validityPeriodAfter" label="开启后有效期" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="reagentUseIssueRecordPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addReagentUseIssueRecord,
  deleteReagentUseIssueRecord,
  updateReagentUseIssueRecord,
  selectReagentUseIssueRecordInfo,
  selectReagentUseIssueRecordList
} from '@/api/quality/reagentUseIssueRecord'
import html2PDF from 'jspdf-html2canvas'
import { Loading } from 'element-ui'

export default {
  data () {
    return {
      reagentUseIssueRecordDialogVisible: false,
      reagentUseIssueRecordFormTitle: '',
      reagentUseIssueRecordForm: {
        id: '',
        productName: '',
        spec: '',
        batchNo: '',
        validPeriod: '',
        manufacturer: '',
        openDate: '',
        enabler: '',
        validityPeriodAfter: '',
        useIssueDetailJson: ''
      },
      reagentUseIssueRecordFormRules: {
        productName: [{ required: true, message: '品名不能为空', trigger: ['blur', 'change']}]
      },
      reagentUseIssueRecordPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: ''
      },
      useIssueDetailList: []
    }
  },
  created () {
    selectReagentUseIssueRecordList(this.searchForm).then(res => {
      this.reagentUseIssueRecordPage = res
    })
  },
  methods: {
    reagentUseIssueRecordDialogClose () {
      this.$refs.reagentUseIssueRecordFormRef.resetFields()
      this.useIssueDetailList = []
    },
    reagentUseIssueRecordFormSubmit () {
      if (this.reagentUseIssueRecordFormTitle === '试剂试药领用、发放记录详情') {
        this.reagentUseIssueRecordDialogVisible = false
        return
      }
      this.$refs.reagentUseIssueRecordFormRef.validate(async valid => {
        if (valid) {
          this.reagentUseIssueRecordForm.useIssueDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.reagentUseIssueRecordFormTitle === '新增试剂试药领用、发放记录') {
            this.reagentUseIssueRecordForm.id = ''
            await addReagentUseIssueRecord(this.reagentUseIssueRecordForm)
          } else if (this.reagentUseIssueRecordFormTitle === '修改试剂试药领用、发放记录') {
            await updateReagentUseIssueRecord(this.reagentUseIssueRecordForm)
          }
          this.reagentUseIssueRecordPage = await selectReagentUseIssueRecordList(this.searchForm)
          this.reagentUseIssueRecordDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.reagentUseIssueRecordFormTitle = '新增试剂试药领用、发放记录'
      this.reagentUseIssueRecordDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteReagentUseIssueRecord(row.id)
        if (this.reagentUseIssueRecordPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.reagentUseIssueRecordPage = await selectReagentUseIssueRecordList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.reagentUseIssueRecordFormTitle = '修改试剂试药领用、发放记录'
      this.reagentUseIssueRecordDialogVisible = true
      this.selectReagentUseIssueRecordInfoById(row.id)
    },
    handleInfo (index, row) {
      this.reagentUseIssueRecordFormTitle = '试剂试药领用、发放记录详情'
      this.reagentUseIssueRecordDialogVisible = true
      this.selectReagentUseIssueRecordInfoById(row.id)
    },
    selectReagentUseIssueRecordInfoById (id) {
      selectReagentUseIssueRecordInfo(id).then(res => {
        this.reagentUseIssueRecordForm.id = res.id
        this.reagentUseIssueRecordForm.productName = res.productName
        this.reagentUseIssueRecordForm.spec = res.spec
        this.reagentUseIssueRecordForm.batchNo = res.batchNo
        this.reagentUseIssueRecordForm.validPeriod = res.validPeriod
        this.reagentUseIssueRecordForm.manufacturer = res.manufacturer
        this.reagentUseIssueRecordForm.openDate = res.openDate
        this.reagentUseIssueRecordForm.enabler = res.enabler
        this.reagentUseIssueRecordForm.validityPeriodAfter = res.validityPeriodAfter
        this.useIssueDetailList = res.useIssueDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectReagentUseIssueRecordList(this.searchForm).then(res => {
        this.reagentUseIssueRecordPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectReagentUseIssueRecordList(this.searchForm).then(res => {
        this.reagentUseIssueRecordPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectReagentUseIssueRecordList(this.searchForm).then(res => {
        this.reagentUseIssueRecordPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `试剂试药领用、发放记录${this.reagentUseIssueRecordForm.openDate.substring(0, 10)}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>
