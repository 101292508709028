import axios from '@/common/axios'
import qs from 'qs'

export function addReagentUseIssueRecord (data) {
  return axios({
    method: 'post',
    url: '/quality/reagentUseIssue/add',
    data: qs.stringify(data)
  })
}

export function deleteReagentUseIssueRecord (id) {
  return axios({
    method: 'delete',
    url: '/quality/reagentUseIssue/delete/' + id
  })
}

export function updateReagentUseIssueRecord (data) {
  return axios({
    method: 'put',
    url: '/quality/reagentUseIssue/update',
    data: qs.stringify(data)
  })
}

export function selectReagentUseIssueRecordInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/reagentUseIssue/info/' + id
  })
}

export function selectReagentUseIssueRecordList (query) {
  return axios({
    method: 'get',
    url: '/quality/reagentUseIssue/list',
    params: query
  })
}
